import './App.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function getScripts() {
  return await (await fetch(`${API_BASE_URL}/scripts`)).json();
}

export async function getScript(name) {
  return await (await fetch(`${API_BASE_URL}/scripts/${name}`)).json();
}

export async function run(script, args) {
  const formData = new FormData();
  formData.set('script', script);
  for (const [name, value] of Object.entries(args)) {
    if (Array.isArray(value)) {
      for (const item of value) {
        formData.append(name, item);
      }
    } else {
      formData.set(name, value);
    }
  }
  const response = await fetch(`${API_BASE_URL}/run`, {
    method: 'POST',
    body: formData,
    mode: 'cors'
  });
  if (response.status === 200) {
    const {run_id: runId} = await response.json();
    return runId;
  } else {
    throw Error(`GET ${API_BASE_URL}/run returned code [${response.status}]`);
  }
}

export async function listFiles(runId) {
  return await (await fetch(`${API_BASE_URL}/run/${runId}/ls`)).json();
}

export async function downloadFile(runId, fileName) {
  return await (await fetch(`${API_BASE_URL}/run/${runId}/file/${encodeURIComponent(fileName)}/download`)).blob();
}

export function getDownloadUrl(runId, fileName) {
  return `${API_BASE_URL}/run/${runId}/file/${encodeURIComponent(fileName)}/download`;
}

export async function streamFile(runId, fileName) {
  return (await fetch(`${API_BASE_URL}/run/${runId}/file/${fileName}/stream`)).body.getReader();
}
