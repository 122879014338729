import { useEffect, useState } from 'react';
import FileSaver from 'file-saver';
import * as api from '../api';

function DownloadFile({runId, status, fileMatcher, autoDownload}) {
  const [fileList, setFileList] = useState([]);
  const [saveFileNames, setSaveFileNames] = useState([]);
  const [downloadedRunId, setDownloadedRunId] = useState(null);

  useEffect(() => {
    async function fetchFileList() {
      const fileList = await api.listFiles(runId);
       setFileList(fileList);
    }
    if (runId && status === '0') {
      fetchFileList();
    }
    if (runId !== downloadedRunId) {
      setDownloadedRunId(null);
    }
  }, [status, runId, downloadedRunId, setDownloadedRunId]);

  useEffect(() => {
    setSaveFileNames(fileList.filter(fileMatcher));
  }, [runId, fileList, fileMatcher]);

  useEffect(() => {
    async function downloadFile(fileName) {
      const fileData = await api.downloadFile(runId, fileName);
      FileSaver.saveAs(fileData, fileName);
    }
    if (runId !== downloadedRunId && saveFileNames.length && autoDownload) {
      for (const saveFileName of saveFileNames) {
        downloadFile(saveFileName);
      }
      setDownloadedRunId(runId);
    }
  }, [runId, saveFileNames, downloadedRunId, setDownloadedRunId, autoDownload]);

  return saveFileNames.length === 0 ? null : (
    <pre>
      <hr/>
      {(saveFileNames.map(saveFileName =>
        <span key={saveFileName}>
          <a href={api.getDownloadUrl(runId, saveFileName)} variant="success">{saveFileName}</a>
          <br/>
        </span>
      ))}
    </pre>
  );
}

export default DownloadFile;
