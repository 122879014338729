import { useEffect, useState } from 'react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Status({runId, onStatusChange}) {
  const [status, setStatus] = useState();

  useEffect(() => {
    async function streamStatus() {
      const statusReader = (await fetch(`${API_BASE_URL}/run/${runId}/file/status/stream`)).body.getReader();
      const {value} = await statusReader.read();
      const statusChunk = new TextDecoder().decode(value);
      setStatus(statusChunk);
      onStatusChange(statusChunk);
    }
    if (runId) {
      streamStatus();
    } else {
      setStatus(undefined);
    }
  }, [runId, onStatusChange]);

  return (
    <>
      {status !== undefined && (
        <><pre>Process exited with code: {status}</pre><hr /></>
      )}
    </>
  );
}

export default Status;
