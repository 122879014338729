import { useEffect, useRef, useState } from 'react';
import * as api from '../api';

function Logs({runId}) {
  const [logs, setLogs] = useState([]);
  const logEndRef = useRef();

  useEffect(() => {
    async function streamLogs() {
      const streamedLogs = [];
      const logReader = await api.streamFile(runId, 'log');
      while (true) {
        const {value, done} = await logReader.read();
        const logChunk = new TextDecoder().decode(value);
        streamedLogs.push(logChunk);
        setLogs([...streamedLogs]);
        if (!runId || done) { break; }
      }
    }
    if (runId) {
      streamLogs();
    } else {
      setLogs([]);
    }
  }, [runId]);

  useEffect(() => {
    if (logs.length) {
      logEndRef.current?.scrollIntoView();
    }
  }, [logs])

  return (
    <div>
      <pre>{logs.join('')}</pre>
      <div ref={logEndRef} />
    </div>
  );
}

export default Logs;
