import Spinner from 'react-bootstrap/Spinner';

function Loading({loading}) {
  return (
    <>
      {loading && (
        <>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        <hr />
        </>
      )}
    </>
  );
}

export default Loading;
