import './App.css';
import { useEffect, useState } from 'react';
import Script from './routes/Script';
import {
  BrowserRouter as Router,
  Link,
  Routes,
  Route
} from "react-router-dom";
import * as api from './api';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function LandingPage({scripts}) {
  const isEnabled = process.env.REACT_APP_LANDING_PAGE_ENABLED === 'true';

  if (!isEnabled) {
    return <></>;
  }

  return (
    <Container style={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <Row>
        <Col style={{display: 'flex', justifyContent: 'center'}}>
          <h1>Welcome to smots!</h1>
        </Col>
      </Row>
      <Row>
        <Col style={{display: 'flex', justifyContent: 'center'}}>
          <h4>Available Scripts</h4>
        </Col>
      </Row>
      <Row>
        <Col style={{display: 'flex', justifyContent: 'center'}}>
        <ul>
        {scripts.map(script => (
          <li key={script}>
            <Link to={script}>{script}</Link>
          </li>
        ))}
        </ul>
        </Col>
      </Row>
    </Container>
  )
}

function App() {
  const [scripts, setScripts] = useState([]);
  useEffect(() => {
    async function fetchScripts() {
      setScripts(await api.getScripts());
    }
    fetchScripts();
  }, []);

  return (
    <Router>
      <Routes>
        {scripts.map(script => (
          <Route key={script} path={script} element={<Script name={script} />}>
            <Route path=':runId' element={<Script name={script} />} />
          </Route>
        ))}
        <Route path='*' element={<LandingPage scripts={scripts}/>}/>
      </Routes>
    </Router>
  );
}

export default App;
