import Dropzone from 'react-dropzone';
import { useState } from 'react';
import Card from 'react-bootstrap/Card';

function FileListPicker({labelText, labelHelp, onFilesChanged}) {
  const [files, setFiles] = useState([]);

  function onDrop(file) {
    const newFiles = [...files, file];
    setFiles(newFiles);
    onFilesChanged(newFiles);
  }

  return (
    <>
      <div style={{display: 'flex', margin: '1em'}}>
        <div style={{flex: 0.5, textAlign: 'center'}}>
        Drag and drop {labelText}
        {labelHelp && <span style={{fontStyle: 'italic'}} className="text-secondary"><br/>{labelHelp}</span>}
        </div>
      </div>
      {files.map(file => (
        <div key={file.name} style={{display: 'flex'}}>
          <Card body style={{flex: 0.5}}>
            <div style={{textAlign: 'center', padding: '0.5em'}}>
              <span className="text-secondary">{file.name}</span>
            </div>
          </Card>
        </div>
      ))}
      <div style={{display: 'flex'}}>
        <Card body style={{flex: 0.5}}>
          <Dropzone onDrop={([file]) => onDrop(file)}>
            {({getRootProps, getInputProps}) => (
              <section>
              <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div style={{textAlign: 'center', padding: '0.5em'}}>
                    <span style={{fontSize: '2em'}}>➕</span>
                  </div>
              </div>
              </section>
            )}
          </Dropzone>
        </Card>
      </div>
    </>
  );
}

export default FileListPicker;
