import Dropzone from 'react-dropzone';
import { useState } from 'react';
import Card from 'react-bootstrap/Card';

function FilePicker({labelText, labelHelp, onFileChanged}) {
  const [file, setFile] = useState();

  function onDrop(file) {
    setFile(file);
    onFileChanged(file);
  }

  return (
    <div style={{display: 'flex'}}>
      <Card body style={{flex: 1}}>
        <Dropzone onDrop={([file]) => onDrop(file)}>
          {({getRootProps, getInputProps}) => (
            <>
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div style={{textAlign: 'center', padding: '0.5em'}}>
                  {file ? <span className="text-secondary">{file.name}</span> : <span style={{fontSize: '2em'}}>➕</span>}
                </div>
              </div>
            </section>
            </>
          )}
        </Dropzone>
      </Card>
      <div style={{flex: 1, marginLeft: '1em', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
        <p style={{textAlign: 'center'}}>{labelText}
        {labelHelp && <span style={{textAlign: 'center', fontStyle: 'italic'}} className="text-secondary"><br/>{labelHelp}</span>}
        </p>
      </div>
    </div>
  );
}

export default FilePicker;
