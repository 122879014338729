import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Logs from '../components/Logs';
import Status from '../components/Status';
import DownloadFile from '../components/DownloadFile';
import * as api from '../api';
import Loading from '../components/Loading';
import minimatch from 'minimatch';
import InputFactory from '../components/InputFactory';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function Script({name}) {
  const {runId} = useParams();
  const navigate = useNavigate();
  const [script, setScript] = useState({});
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const [autoDownload, setAutoDownload] = useState(false);

  useEffect(() => {
    (async () => {
      setScript((await api.getScript(name)).config);
    })();
  }, [name]);

  useEffect(() => {
    setStatus(undefined);
  }, [runId]);

  useEffect(() => {
    if (runId !== undefined) {
      setLoading(status === undefined);
    }
  }, [runId, status]);

  function onFileChanged(name, file) {
    setForm({
      ...form,
      [name]: file
    });
  }

  function setFormProperty(name, value) {
    setForm({
      ...form,
      [name]: value
    })
  }

  function fileMatcher(file) {
    return minimatch(file, script.output?.file ?? '');
  }

  async function runScript() {
    try {
      setLoading(true);
      const apiRunId = await api.run(name, form);
      navigate(apiRunId);
      setAutoDownload(true);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  return (
    <>
      <Container>
        {(script.input || []).map(item => (
          <Row key={item.name} style={{"margin": "1em"}}>
            <Col lg={8} xxl={6}>
              <InputFactory item={item} form={form} setFormProperty={setFormProperty} onFileChanged={onFileChanged}/>
            </Col>
          </Row>
        ))}
        <Row style={{"margin": "1em"}}>
          <Col style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Button variant="primary" onClick={runScript} disabled={loading}>RUN</Button>
          </Col>
        </Row>
        <Row key={runId} className="justify-content-md-center" style={{"margin": "1em"}}>
          <Col>
            <Card body>
              <Loading loading={loading}/>
              <Status runId={runId} onStatusChange={setStatus}/>
              <div style={{height: '20em', overflowY: 'auto'}}>
                <Logs runId={runId}/>
              </div>
              <DownloadFile runId={runId} status={status} fileMatcher={fileMatcher} autoDownload={autoDownload}/>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Script;
