import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FilePicker from '../components/FilePicker';
import FileListPicker from '../components/FileListPicker';

function InputFactory({item, form, setFormProperty, onFileChanged}) {
  return (
    <>
      {(item.type === 'text') && (
        <Form.Group as={Row} style={{display: 'flex'}}>
          <Form.Label column style={{flex: 1}}>{item.label}</Form.Label>
          <Col style={{flex: 2}}>
            <Form.Control type="text" value={form[item.name] || ''} onChange={(event) => setFormProperty(item.name, event.target.value)}/>
          </Col>
        </Form.Group>
      )}
      {(item.type === 'password') && (
        <Form.Group as={Row} style={{display: 'flex'}}>
          <Form.Label column style={{flex: 1}}>{item.label}</Form.Label>
          <Col style={{flex: 2}}>
            <Form.Control type="password" value={form[item.name] || ''} onChange={(event) => setFormProperty(item.name, event.target.value)}/>
          </Col>
        </Form.Group>
      )}
      {item.type === 'file' && (
        <FilePicker
          labelText={`Drag and drop the ${item.label}`}
          labelHelp={item.help}
          onFileChanged={file => onFileChanged(item.name, file)} />
      )}
      {item.type === 'filelist' && (
        <FileListPicker
          labelText={item.label}
          labelHelp={item.help}
          onFilesChanged={files => onFileChanged(item.name, files)} />
      )}
    </>
  );
}

export default InputFactory;
